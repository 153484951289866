<!-- @format -->

<template>
  <div v-if="options" style="margin: 0 5%">
    <div class="d-flex align-items-center">
      <div>
        <h4 class="mb-0" v-if="options.length > 0">
          <!--          {{ options[0].cate.title }}-->
        </h4>
        <h4 v-else>No article</h4>
      </div>
    </div>
    <!-- collapse -->
    <!--    <app-collapse id="faq-payment-qna" accordion type="margin" class="mt-2">-->
    <!--      <app-collapse-item v-for="(data, index) in options" :key="index" :title="data.title">-->
    <!--        <div v-html="data.content"></div>-->
    <!--      </app-collapse-item>-->
    <!--    </app-collapse>-->
    <div>
      <b-row>
        <b-col
            v-for="(categoryObj, categoryName) in options"
            :key="categoryName.id"
            :xl="3"
            :md="6"
            :sm="12"
            :xs="12"
        >
          <b-card class="mt-2 faq" style="padding: 0;width: 100%">
            <b-card-body style="padding: 0">
              <b-img
                  v-if="categoryObj.cover"
                  :src="categoryObj.cover"
                  alt="Medal Pic"
                  style="width: 100%; height: 100%; object-fit: cover"
                  @error="errorImg"
              />
              <b-img
                  v-if="!categoryObj.cover"
                  :src="require('@/assets/article_default.png')"
                  style="width: 100%; height: 100%; object-fit: cover"
              />
              <router-link
                  :to="{name:'vip_tutorial-library-training&tutorial-detail',query:{id:categoryObj.id,title:title,cate_id:cate_id}}"
              >
                <div class="body">
                  <h4 class="hover title" @click="goDetail(categoryObj)">{{ categoryObj.title }}</h4>
                  <div class="desc hover">
                    {{ categoryObj.description || '' }}
                  </div>
                </div>
              </router-link>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { BAvatar, BCard, BCardBody, BRow, BCol, BImg } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import articleDefault from '@/assets/article_default.png'

export default {
  components: {
    BAvatar,
    AppCollapseItem,
    BImg,
    AppCollapse,
    BCard,
    BCardBody,
    BRow,
    BCol,
  },
  props: {
    options: {
      type: [Array, Object],
      default: () => {
      },
    },
    cate_id: {
      type: [String, Number],
      default: () => {
      },
    },
    title: {
      type: String,
      default: () => {
      },
    },
  },
  data() {
    return {
      defaultImg: articleDefault
    }
  },
  witch: {},
  mounted() {
    setTimeout(() => {
    }, 2000)
  },
  methods: {
    errorImg(e) {
      e.target.src = require('@/assets/article_default.png')
      e.target.onerror = null //防止闪图
    },
    ceshi() {
      console.log(this.options)
    },
    goDetail(item) {
    }
  },
}
</script>
<style lang="scss">
.faq {
  width: 320px;


  .card-body {
    padding: 0 !important;
  }

  .body {
    padding: 1.5rem;
  }

  .title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2; /* 显示的行数 */
  }

  .desc {
    font-size: 14px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2; /* 显示的行数 */
  }

  .hover:hover {
    color: #445f79;
    cursor: pointer;
  }
}
</style>
